@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General */

html {
  scroll-behavior: smooth;
}

.loading {
  display: flex;
  background-color: rgba(255,255,255,1);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.highlight {
  color: #F17291;
}

.loading img {
  width: 150px;
  margin: auto;
  transform: scale(1);
}

.yoga-omline {
  overflow: hidden;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.yoga-omline section {
  scroll-snap-align: center;
}

.wrapper {
  max-width: 1336px;
}

.yoga-buy {
  font-family: 'Montserrat';
  font-weight: 700;
  background-color: #F17291;
  border-radius: 100px;
  border: 0px;
  color: #fff;
  text-transform: uppercase;
  padding: 7.5px 10px;
  cursor: pointer;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all .5s;
}

.yoga-buy:hover {
  background-color: #F96291;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all .5s;
  transform: scale(1.2);
}

.modal-text {
  font-family: 'Montserrat';
  color: #222;
  padding: 30px;
}

/* Nav */

.yoga-header .bars {
  display: none;
}

.yoga-header {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 0px 10px #ddd;
}

.yoga-header nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0px;
}

.yoga-header nav ul li {
  list-style-type: none;
  margin: 0px 15px;
}

.yoga-header nav ul li a {
  color: #A3A3A3;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: small;
  text-decoration: none;
  text-transform: uppercase
}

.yoga-header nav ul li img {
  width: 75px;
}

.menu-link:hover {
  color: #F17291;
}

/* Section Video */

.section-video {
  z-index: 10;
  position: relative;
  background-image: url(../../static/media/fundo-yoga.29390046.webp);
  background-size: contain;
  background-position: center;
  width: 110%;
  margin-left: -5%;
  margin-right: -5%;
  border-radius: 0% 0% 100% 100%;
  overflow: hidden;
}

.section-video__overlay {
  display: flex;
  justify-content: center;
  background-color: rgba(255,255,255,.8);
  padding-bottom: 60px;
  padding-top: 120px;
}

.section-video__grid {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.section-video__logo {
  max-width: 200px;
  padding: 50px;
}

.section-video__media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-video__media iframe {
  aspect-ratio: 16/9;
  width: 720px;
  height: 405px;
}

.section-video__media label {
  color: #A3A3A3;
  font-family: 'Montserrat';
  font-weight: 700;
  margin-bottom: 15px;
}

.section-video .yoga-buy {
  margin: 30px;
  padding: 15px 30px;
}

/* Section Healthy */

.section-healthy {
  background: #F17291;
  width: 100%;
  margin-top: -500px;
  padding-top: 500px;
  z-index: 9;
  text-align: center;
  box-shadow: 0px 0px 10px #eee;
}

.section-healthy__grid {
  padding: 120px 30px;
  margin: auto;
}

.section-healthy h2 {
  color: #fff;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: xx-large;
  font-style: oblique;
  text-align: center;
  max-width: 680px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}

.section-healthy h2 > span {
  color: #fff;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: oblique;
}

.section-healthy__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.section-healthy__content img {
  max-width: 230px;
  margin: 15px;
}

.section-healthy__content p {
  color: #fff;
  font-size: larger;
  font-family: 'Montserrat';
  text-align: justify;
  max-width: 320px;
  margin: 15px;
}

/* Offer */

.section-offer {
  background-image: url(../../static/media/offer-img.26196934.webp);
  background-position: center;
  background-size: cover;
  background-color: #333333;
  background-blend-mode: luminosity;
  padding: 120px 30px;
}

.section-offer h2 {
  color: #fff;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: xx-large;
}

.section-offer p {
  color: #fff;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: larger;
  max-width: 480px;
  text-align: center;
  margin: auto;
}

/* Provides */

.section-provides {
  padding: 120px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px #eee;
}

.section-provides h2 {
    color: #F489A0;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: xx-large;
    margin-bottom: 60px;
}

.section-provides ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  flex-wrap: wrap;
}

.section-provides li {
  list-style-type: none;
  margin: 10px;
}

.section-provides .provides__list__title {
  font-family: 'Montserrat';
  font-style: italic;
  font-size: large;
  font-weight: 800;
  color: #1D529F;
}

.section-provides .provides__list__bullet {
  background: linear-gradient(0deg, #1D529F 0%, rgba(29,82,159,1) 50%, rgba(168,219,210,1) 100%);
  border-radius: 100px;
  border: 1px solid #1D529F;
  font-size: large;
  padding: 15px 20px;
  color: #fff;
}

.section-provides .provides__list__title--gray {
  color: #A3A3A3;
}

.section-provides .provides__list__bullet--gray {
  background: #fff;
  color: #A3A3A3;
  border: 1px solid #A3A3A3;
}

.section-provides p {
  color: #A3A3A3;
  text-align: center;
  margin-top: 60px;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  line-height: 24px;
}

/* BENEFITS */

.section-benefits {
  background: #1D529F;
  padding: 120px 30px;
}


.section-benefits h2 {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: xx-large;
  margin-bottom: 15px;
}

.section-benefits h3 {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: large;
  margin: 0px 0px 60px;
}

.section-benefits ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 996px;
  margin: 0px auto;
  padding: 0px;
}

.section-benefits li {
  list-style-type: none;
  color: #fff;
  display: flex;
  margin: 20px;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
  text-align: center;
}

.section-benefits li img{
  max-width: 75px;
  margin: 15px;
}


/* INCLUDES */

.section-includes {
  padding: 120px 30px;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 0px 10px #eee;
}

.section-includes h2 {
  color: #22529D;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: xx-large;
  margin-bottom: 15px;
}

.section-includes h3 {
  color: #777777;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: large;
  margin: 0px 0px 60px;
}

.section-includes ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
}

.section-includes li {
  list-style-type: none;
  margin: 30px;
}

.section-includes img {
    max-width: 250px;
    position: relative;
}

.section-includes h4 {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: italic;
  background-color: #E3E3E3;
  padding: 10px 15px;
  font-size: large;
  color: #fff;
  border-radius: 100px;
  text-align: center;
  margin-top: -30px;
  position: relative;
}

.section-includes h4#hatha {
  background-color: #1D529F;
} 

.section-includes h4#vinyasa {
  background-color: #F17391;
} 

.section-includes h4#kundalini {
  background-color: #1D529F;
}

.section-includes h4#minutos {
  background-color: #F17391;
} 

.section-includes h4#meditacoes {
  background-color: #1D529F;
} 





.section-includes .includes__extra {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
}

.section-includes .includes__extra span {
  font-family: 'Montserrat';
}

.section-includes .includes__extra h4 {
  color: #222;
  padding: 20px;
}

/* EXTRA */

.section-extra {
  padding: 120px 30px;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 0px 10px #eee;
  background-color: #F17291;
  position: relative;
}

.section-extra__background {
  background-image: url(../../static/media/extra-bg.47db5dac.webp);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  opacity: .05;
  position: absolute;
  mix-blend-mode: luminosity;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.section-extra h2 {
  color: #fff;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: xx-large;
  margin-bottom: 60px;
}

.section-extra .extra__book {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.section-extra .extra__book img{
  max-width: 300px;
  z-index: 5;
  position: relative;
  margin-left: -25px;
}

.section-extra .extra__book__text {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px 30px 30px 60px;
  max-width: 400px;
  height: 100px;
  margin-left: -30px;
  box-shadow: 0px 0px 5px #ddd;
}

.section-extra .extra__book p{
  font-family: 'Montserrat';
  color: #222;
  font-weight: 500;
  z-index: 4;
  position: relative;
}


/* INSTRUCTORS */

.section-instructors {
  padding: 120px 30px;
}

.section-instructors h2 {
  color: #A3A3A3;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: xx-large;
  margin-bottom: 60px;
}

.instructors__grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.instructors__item {
  text-align: center;
  max-width: 250px;
  margin: 120px
}

.instructors__item img{
  max-width:150px;
}

.instructors__item h4 {
  text-align: center;
  color: #A3A3A4;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: large;
  font-style: oblique;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.instructors__item h5 {
  text-align: center;
  color: #A3A3A4;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: large;
  font-style: oblique;
  margin-top: 0px;
  margin-bottom: 0px;
}

.instructors__item__social {
  margin: 15px 0px;
}

.instructors__item p {
  text-align: center;
  color: #A3A3A4;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: medium;
  margin-top: 0px;
}

.instructors__item button {
  border: 0px;
  background-color: #A8DBD2;
  padding: 10px 15px;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: 800;
  border-radius: 100px;
  margin-top: 15px;
  cursor: pointer;
}

/* MESSAGES */

.section-messages {
  background-color: #A8DBD2;
  padding: 120px 30px;
  box-shadow: 0px 0px 10px #eee;
}

.section-messages h2 {
  color: #fff;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: xx-large;
  margin-bottom: 120px;
}

.section-messages h3 {
  color: #fff;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: large;
  margin-top: 0px;
  margin-bottom: 60px;
}

.messages__item {
  border: #ccc 1px solid;
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 250px;
  margin-top: 50px;
}

.messages__item img {
  margin-top: -80px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.messages__item h4 {
  font-family: 'Montserrat';
  font-weight: 800;
  color: #585858;
  font-size: large;
  text-align: center;
  margin-bottom: 0px;
}

.messages__item p {
  color: #585858;
  font-family: 'Montserrat';
  font-size: medium;
  text-align: center;
}

.section-messages .slick-slide > div {
  text-align: center;
}

.section-messages .slick-dots {
  bottom: -60px!important;
}

/* PRICES */

.section-prices {
  padding: 120px 30px;
  background-image: url(../../static/media/fundo-yoga.29390046.webp);
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px #eee;
}

.section-prices h2 {
  color: #F17291;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: xx-large;
  margin-bottom: 120px;
}

.prices__card {
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  padding: 30px;
  max-width: 300px;
}

.prices__card h4 {
  font-family: 'Montserrat';
  font-weight: 800;
  color: #222;
  font-style: italic;
  font-size: large;
  margin: 0px;
}

.prices__card h5 {
    font-family: 'Montserrat';
    font-weight: 400;
    color: #222;
    font-style: italic;
    font-size: medium;
    margin: 0px;
}

.prices__card .prices__value {
  margin: 30px 0px
}

.prices__card .prices__value span{
  color: #A3A3A3;
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: italic;
}

.prices__card .prices__value p{
  color: #222;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: xx-large;
  letter-spacing: 3px;
  font-style: italic;
  margin: 0px;
}

.prices__card .prices__content {
  margin-bottom: 30px;
}

.prices__card .prices__content p {
  font-family: 'Montserrat';
  font-weight: 700;
}

.prices__card .prices__content ul {
  font-style: italic;
  font-size: medium;
  padding: 0px;
}

.prices__card .prices__content li{
  list-style-type: none;
  font-family: 'Montserrat';
  margin: 10px;
}

.prices__card .yoga-buy {
  padding: 15px 30px;
  border: 1px solid #E27299;
}

/* FOOTER */

footer {
  background-color: #1D529F;
  padding: 60px;
  display: flex;
  justify-content: center;
}

footer img{
  width: 100px;
  height: 60px;
}

.to-animate {
  opacity: 0;
}

.fadeElement {
  animation: fadeElement 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s both;
}

.loadingElement {
  overflow: hidden;
  animation: loadingElement 2s cubic-bezier(0.165, 0.84, 0.44, 1) 3s both;
}

.loadingLogo {
  animation: loadingLogo 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@keyframes fadeElement {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }

    100% {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes loadingLogo {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loadingElement {
  0% {
    opacity: 1;
  }

  98% {
    opacity: 0;
  }

  99% {
    opacity: 0;
    height: 100%;
  }
  100% {
    opacity: 0;
    height: 0px;
  }
}


@media (max-width: 996px) {

  .section-video {
    border-radius: 0%;
  }

  .section-video__media iframe {
    width: 95%;
    height: auto;

  }

  .section-provides ul {
    flex-direction: column;
    margin-bottom: 60px;
  }

.section-extra .extra__book__text {
    padding: 140px 30px 90px 30px;
    text-align: center;
    margin: -120px auto 30px;
}

.instructors__item {
  margin: 0px 0px 60px;
}

.messages__item {
  width: 70%!important;
}

.yoga-header {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 0px 10px #ddd;
  height: 100px;
  overflow: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all .5s;
}

.yoga-header.expanded {
  height: 420px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all .5s;
}

.yoga-header nav ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0px;
}

.yoga-header nav ul li{
  margin: 15px;
}

.yoga-header .bars {
  display: block;
  position: absolute;
  right: 25px;
  top: 35px;
  font-size: 24px;
  color: #aeaeae;
}

.yoga-header .bars.active {
  position: absolute;
  right: 25px;
  top: 35px;
  font-size: 24px;
  color: #F17291;
}


}

